import React, { Component } from 'react';
import { FormGroup, Label, Row, Form, Col, CardHeader, Card, CardBody, Spinner, FormFeedback, Input, Alert, Progress, Button } from 'reactstrap';
import _ from 'underscore';
import { Global } from '~/App'
import IngresaCuenta from '~/components/IngresaCuenta'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import API from '../API';

const loadEvent = new Event('pagaTuCuentaLoaded')

class PagaTuCuenta extends Component {

  constructor(props) {
    super(props);
    this.state = {
      servicio: null,
      loading: true,
      opcionPago: props.location.state && props.location.state.orden ? props.location.state.orden : 0,
      donacion: 0,
      formaPago: null,
      correoValido: null,
      mensajes: []
    };
    this.Consultar = this.Consultar.bind(this);
    try {
      this.inIframe = window.self !== window.top
    } catch {
      this.inIframe = true;
    }
  }

  componentWillMount() {
    this.ConsultaServicio(this.props)
  }

  componentWillReceiveProps(props) {
    this.ConsultaServicio(props)
  }

  ConsultaServicio(props) {
    if (props.location.state && props.location.state.empresa && props.location.state.servicio && (Global.csd || props.location.state.documento)) {
      this.Consultar(props.location.state.empresa, props.location.state.servicio, props.location.state.documento, props.location.state.captcha)
    } else if (props.location.state && props.location.state.captcha && props.location.state.comuna !== "" && props.location.state.calle !== "") {
      this.ConsultarDireccion(props.location.state.empresa, props.location.state.captcha, props.location.state.comuna, props.location.state.calle, props.location.state.noCasa, props.location.state.noDpto, props.location.state.noCasa2)
    } else if (props.location.state && props.location.state.token) {
      this.ConsultarToken(props.location.state.empresa, props.location.state.token)
    } else if (props.Usuario) {
      if (!props.location.state || !props.location.state.servicio) {
        props.history.replace("/sucursal")
      } else {
        this.Consultar(props.location.state.empresa, props.location.state.servicio, null)
      }
    }
  }
  async ConsultarDireccion(empresa, Captcha, Comuna, Calle, NoCasa, NoDpto, NoCasa2) {
    var formaPago = (Global.empresas.find(o => o.key == empresa) || this.state.servicio || {}).otros?.RecaudadorDefecto;
    try {
      this.setState({ loading: true });
      var datos = { Captcha, Comuna, Calle, NoCasa, NoDpto, NoCasa2 };
      var url = `Consulta/${empresa}/Servicio/Direccion`;
      const response = await API.Call(url, datos);
      var recaudadores = Object.entries((Global.empresas.find(o => o.key == (empresa || (this.props.location.state || {}).empresa)) || {}).recaudadores || {}).filter(o => o[1]);
      if (recaudadores.length == 1 && !formaPago) {
        formaPago = recaudadores[0][0];
      }
      this.setState({ formaPago, servicioFalso: response.servicioFalso, deuda: response.deuda, direccion: response.direccion, loading: false, mensajes: [] });
    } catch (e) {
      this.setState({ formaPago, servicio: null, loading: false, mensajes: [{ tipo: "danger", texto: e }] });
    }
    document.getElementById('Portal-Clientes').scrollIntoView({ behavior: 'smooth', block: 'start' });
    document.getElementById("Portal-Clientes").dispatchEvent(loadEvent);
  }
  async ConsultarToken(empresa, token) {
    var formaPago = (Global.empresas.find(o => o.key == empresa) || this.state.servicio || {}).otros?.RecaudadorDefecto;
    try {
      this.setState({ loading: true });
      var datos = { token };
      var url = `Consulta/${empresa}/Servicio/Token`;
      const response = await API.Call(url, datos);
      var recaudadores = Object.entries((Global.empresas.find(o => o.key == (empresa || (this.props.location.state || {}).empresa)) || {}).recaudadores || {}).filter(o => o[1]);
      if (recaudadores.length == 1 && !formaPago) {
        formaPago = recaudadores[0][0];
      }
      this.setState({ formaPago, servicioFalso: response.servicioFalso, direccion: response.direccion, deuda: response.deuda, loading: false, mensajes: [] });
    } catch (e) {
      this.setState({ formaPago, servicio: null, loading: false, mensajes: [{ tipo: "danger", texto: e }] });
    }
    document.getElementById('Portal-Clientes').scrollIntoView({ behavior: 'smooth', block: 'start' });
    document.getElementById("Portal-Clientes").dispatchEvent(loadEvent);
  }

  async Consultar(empresa, servicio, documento, captcha = null) {
    var formaPago = (Global.empresas.find(o => o.key == empresa) || this.state.servicio || {}).otros?.RecaudadorDefecto;
    try {
      this.setState({ loading: true })
      var response;
      if (captcha) {
        response = await API.Call(`Consulta/${empresa}/Servicio/${servicio}`, { captcha });
      } else {
        var url = documento ? `Consulta/${empresa}/Servicio/${servicio}/${documento}`
          : `Consulta/${empresa}/Servicio/${servicio}/Pagar`;
        response = await API.Call(url);
      }
      var recaudadores = Object.entries((Global.empresas.find(o => o.key == (empresa || (this.props.location.state || {}).empresa)) || { servicio, ...response }).recaudadores || {}).filter(o => o[1]);
      if (recaudadores.length == 1 && !formaPago) {
        formaPago = recaudadores[0][0];
      }
      this.setState({ formaPago, servicio: { servicio, ...response }, loading: false, mensajes: [] });
    } catch (e) {
      this.setState({ formaPago, servicio: null, loading: false, mensajes: [{ tipo: "danger", texto: e }] });
    }
    document.getElementById('Portal-Clientes').scrollIntoView({ behavior: 'smooth', block: 'start' });
    document.getElementById("Portal-Clientes").dispatchEvent(loadEvent);
  }

  render() {
    var recaudadores = (Global.empresas.find(o => o.key == (this.props.location.state || {}).empresa) || this.state.servicio || {}).recaudadores || {};
    if (this.state.servicioFalso) {
      return (
        <Card>
          <CardHeader className="titulo-modal paga-tu-cuenta"><span>Paga tu Cuenta</span></CardHeader>
          <CardBody className="cuerpo-modal">
            <Row>
              <Col sm={{ size: 8, offset: 2 }}>
                <div className="cuadroPago">
                  <h4>Total a Pagar</h4>
                  <br />
                  {/* {this.props.location.state.token? */}<p>Usted está pagando: {Global.toTitleCase(this.state.direccion)}</p>{/* :
                <p>{`Usted está pagando: ${Global.toTitleCase(this.props.location.state.calle)} ${Global.toTitleCase(this.props.location.state.noCasa)}${this.props.location.state.noDpto ? (", " + Global.toTitleCase(this.props.location.state.noDpto)) : ""}${this.props.location.state.noCasa2? (", "+Global.toTitleCase(this.props.location.state.noCasa2)) : ""}, ${Global.toTitleCase(this.props.location.state.comuna)}`}</p>} */}
                  <br />
                  <p className="float-left">
                    <h6 style={{ display: "inline" }}>Deuda</h6>
                  </p>
                  <p className="float-right">
                    <strong><h6 style={{ display: "inline" }}>$ {Global.FormatearNumero(this.state.deuda)}</h6> <small>CLP</small></strong>
                  </p>
                  <div style={{ clear: "both" }}></div>
                  {!this.props.Usuario && <Link to="/registrate">Regístrate para ver tus documentos históricos</Link>}
                  <hr />
                  <p className="float-left">
                    <h5 style={{ display: "inline" }}>Total</h5>
                  </p>
                  <p className="float-right">
                    <strong><h5 style={{ display: "inline" }}>$ {Global.FormatearNumero(this.state.deuda)}</h5> <small>CLP</small></strong>
                  </p>
                  <div style={{ clear: "both" }}></div>
                  <br />
                  <Form method="post" target={this.inIframe ? "_blank" : null} action={this.state.formaPago ? `${Global.UrlApi}/Recaudacion/${(this.props.location.state || {}).empresa}/OrdenDePago/${Global.toTitleCase(this.state.formaPago)}/Generar/Direccion` : ""}>
                    {!this.props.Usuario &&
                      <FormGroup>
                        <Label for="correo"><h6>Correo electrónico</h6>
                          <small>Para enviar Comprobante de Pago</small></Label>
                        <Input invalid={!this.state.correoValido} onChange={e => this.setState({ correoValido: Global.ValidateEmail(e.target.value) })} bsSize="sm" type="email" name="correo" placeholder="Ingrese su Correo" />
                        <FormFeedback>Debe ser un correo válido</FormFeedback>
                        <br />
                      </FormGroup>}
                    <h6>Forma de Pago</h6>
                    <br />
                    <div className="text-center">
                      {recaudadores.transbank &&
                        <FormGroup className="transbank" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "transbank"} onClick={() => this.setState({ formaPago: "transbank" })} />{' '}
                            <img src="https://cdn.smartx.cl/images/logo-webpay-plus.png" width="60" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.flow &&
                        <FormGroup className="flow" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "flow"} onClick={() => this.setState({ formaPago: "flow" })} />{' '}
                            <img src="https://recaudador.asyntec.com/logoflow.png" width="60" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.khipu &&
                        <FormGroup className="khipu" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "khipu"} onClick={() => this.setState({ formaPago: "khipu" })} />{' '}
                            <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2015/110x50-transparent.png" width="80" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.mach &&
                        <FormGroup className="mach" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "mach"} onClick={() => this.setState({ formaPago: "mach" })} />{' '}
                            <img src="https://recaudador.asyntec.com/logomach.png" width="80" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.bci &&
                        <FormGroup className="bci" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "bci"} onClick={() => this.setState({ formaPago: "bci" })} />{' '}
                            <img src="https://recaudador.asyntec.com/logobci.png" width="80" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.servipag &&
                        <FormGroup className="servipag" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "servipag"} onClick={() => this.setState({ formaPago: "servipag" })} />{' '}
                            <img src="https://cdn.smartx.cl/images/logo-servipag.png" width="60" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.unired &&
                        <FormGroup className="unired" check inline>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "unired"} onClick={() => this.setState({ formaPago: "unired" })} />{' '}
                            <img src="https://recaudador.asyntec.com/unired.png" width="60" />
                          </Label>
                        </FormGroup>}
                      {recaudadores.linkpago &&
                        <FormGroup className="linkpago" check inline style={{ display: "none" }}>
                          <Label check>
                            <Input type="radio" checked={this.state.formaPago == "linkpago"} onClick={() => this.setState({ formaPago: "linkpago" })} />
                          </Label>
                        </FormGroup>}
                    </div>
                    <p>
                      {API.Cookie && <input type="hidden" name="token" value={API.Cookie} />}
                      <input type="hidden" name="monto" value={this.state.deuda} />
                      <input type="hidden" name="servicioFalso" value={this.state.servicioFalso} />
                      <Button style={{ marginTop: "2rem", float: "right" }} /* onClick={this.Pagar} */ color="primary" disabled={!this.state.formaPago || !this.state.deuda || (!this.props.Usuario && !this.state.correoValido)}>Pagar</Button>
                    </p>
                    <div style={{ clear: "both" }}></div>
                  </Form>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>

      );
    }
    var pago = this.state.servicio && (_.isEmpty(this.state.servicio.Documentos) ? 0 : this.state.servicio.Documentos[this.state.opcionPago].aPagar) + this.state.donacion;
    return (
      <div>
        {!this.props.location.state || (!this.state.servicio && !this.state.loading) ?
          <Card>
            <CardHeader className="titulo-modal paga-tu-cuenta"><span>Paga tu Cuenta</span></CardHeader>
            <CardBody className="cuerpo-modal">
              <Row>
                <Col sm={{ size: 6, offset: 3 }} className="bordeCompleto">
                  <h4 className="text-muted ingrese-datos-cuenta"><small><span>Ingrese los datos de su cuenta</span></small></h4>
                  <Link to={"/sucursal"}>{"Tienes cuenta? Inicia Sesión"}</Link>
                  <br />
                  {_.map(this.state.mensajes, (m, i) => <Alert key={i} color={m.tipo}>
                    {m.texto}
                  </Alert>)
                  }
                  <br />
                  <IngresaCuenta {...this.props} />
                </Col>
              </Row>
            </CardBody>
          </Card>
          :
          this.state.loading ?
            <Spinner animation="border" role="status">
              {"Cargando..."}
              {/* <span className="sr-only">Cargando...</span> */}
            </Spinner> :
            <Card>
              <CardHeader className="titulo-modal paga-tu-cuenta"><span>Paga tu Cuenta</span></CardHeader>
              <CardBody className="cuerpo-modal">
                <Row>
                  <Col sm={5} className="bordeIzquierdoSimple">
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={12}>
                            <h4>Cuenta {Global.AgregaDV(this.state.servicio.servicio)}</h4>
                            <h4 className="text-muted"><small>{(Global.empresas.find(o => o.key == (this.props.location.state || {}).empresa) || { nombre: this.state.servicio.nombreEmpresa }).nombre}</small></h4>
                            <br />
                            {this.state.servicio.deuda_vencida ?
                              <Alert color="warning">
                                {this.state.servicio.estado_corte}
                              </Alert> : null}
                          </Col>
                          <Col sm={12}>
                            {_.isEmpty(this.state.servicio.Documentos) ?
                              <Alert color="success">
                                Sin deuda
                              </Alert> :
                              <div className="text-center">
                                <Progress style={{ minHeight: "3rem", marginBottom: "1rem" }} multi>
                                  <Progress bar color="danger" value={this.state.servicio.deuda_vencida} max={this.state.servicio.deuda_actual}><strong>Deuda Vencida</strong><h6>$ {Global.FormatearNumero(this.state.servicio.deuda_vencida)}</h6></Progress>
                                  <Progress bar color="primary" value={this.state.servicio.deuda_actual - this.state.servicio.deuda_vencida} max={this.state.servicio.deuda_actual}><strong>Deuda Sin Vencer</strong><h6>$ {Global.FormatearNumero(this.state.servicio.deuda_actual - this.state.servicio.deuda_vencida)}</h6></Progress>
                                </Progress>
                              </div>}
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12}>
                        <hr />
                        <h4>Datos Generales</h4>
                        <Row>
                          <Col xs={3}><strong>Titular</strong></Col>
                          <Col xs={9}>{Global.toTitleCase(this.state.servicio.nom_cliente)}</Col>
                        </Row>
                        <Row>
                          <Col xs={3} className="direccion-domicilio"><strong><span>Dirección</span></strong></Col>
                          <Col xs={9}>{Global.toTitleCase(this.state.servicio.dir_instal)}, {Global.toTitleCase(this.state.servicio.comuna_instal)}</Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={7} className="bordeDerecho">
                    <Row>
                      <Col sm={7} className="opcionesPago">
                        <h4>Opciones de Pago</h4>
                        <br />
                        {this.state.servicio.Documentos.map((d, i) =>
                          <div className={"opcionPagar" + (i == this.state.opcionPago ? " seleccionada" : "")} onClick={() => this.setState({ opcionPago: i })}>
                            {d.nombre_movimiento} {d.aPagar <= this.state.servicio.deuda_vencida ? "(Vencida)" : ""}<br />
                            <strong>$ {Global.FormatearNumero(d.aPagar)} <small>CLP</small></strong>
                          </div>
                        )}
                        <br />
                        {this.state.servicio.donacion_a_pagar ?
                          <div className={"opcionPagar" + (this.state.donacion == this.state.servicio.donacion_a_pagar ? " seleccionada" : "")} onClick={() => this.setState({ donacion: this.state.donacion == this.state.servicio.donacion_a_pagar ? 0 : this.state.servicio.donacion_a_pagar })}>
                            Donación<br />
                            <strong>$ {Global.FormatearNumero(this.state.servicio.donacion_a_pagar)} <small>CLP</small></strong>
                          </div> : null}
                        {!this.props.Usuario && <Link to="/registrate">Regístrate para ver tus documentos históricos</Link>}
                        <div className="opciones-pago-filler"></div>
                      </Col>
                      <Col sm={5}>
                        <div className="cuadroPago">
                          <h4>Total a Pagar</h4>
                          <br />
                          <p className="float-left">
                            <h6 style={{ display: "inline" }}>Deuda</h6>
                          </p>
                          <p className="float-right">
                            <strong><h6 style={{ display: "inline" }}>$ {_.isEmpty(this.state.servicio.Documentos) ? 0 : Global.FormatearNumero(this.state.servicio.Documentos[this.state.opcionPago].aPagar)}</h6> <small>CLP</small></strong>
                          </p>
                          <div style={{ clear: "both" }}></div>
                          {this.state.donacion ?
                            <div>
                              <p className="float-left">
                                <h6 style={{ display: "inline" }}>Donación</h6>
                              </p>
                              <p className="float-right">
                                <strong><h6 style={{ display: "inline" }}>$ {Global.FormatearNumero(this.state.donacion)}</h6> <small>CLP</small></strong>
                              </p>
                              <div style={{ clear: "both" }}></div>
                            </div> : null}
                          <hr />
                          <p className="float-left">
                            <h5 style={{ display: "inline" }}>Total</h5>
                          </p>
                          <p className="float-right">
                            <strong><h5 style={{ display: "inline" }}>$ {_.isEmpty(this.state.servicio.Documentos) ? 0 : Global.FormatearNumero(this.state.servicio.Documentos[this.state.opcionPago].aPagar + this.state.donacion)}</h5> <small>CLP</small></strong>
                          </p>
                          <div style={{ clear: "both" }}></div>
                          <br />
                          <Form method="post" target={this.inIframe ? "_blank" : null} action={this.state.formaPago ? `${Global.UrlApi}/Recaudacion/${(this.props.location.state || {}).empresa}/OrdenDePago/${Global.toTitleCase(this.state.formaPago)}/Generar` : ""}>
                            {!this.props.Usuario &&
                              <FormGroup>
                                <Label for="correo"><h6>Correo electrónico</h6>
                                  <small>Para enviar Comprobante de Pago</small></Label>
                                <Input invalid={!this.state.correoValido} onChange={e => this.setState({ correoValido: Global.ValidateEmail(e.target.value) })} bsSize="sm" type="email" name="correo" placeholder="Ingrese su Correo" />
                                <FormFeedback>Debe ser un correo válido</FormFeedback>
                                <br />
                              </FormGroup>}
                            <h6>Forma de Pago</h6>
                            <br />
                            <div className="text-center">
                              {recaudadores.transbank &&
                                <FormGroup className="transbank" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "transbank"} onClick={() => this.setState({ formaPago: "transbank" })} />{' '}
                                    <img src="https://cdn.smartx.cl/images/logo-webpay-plus.png" width="60" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.flow &&
                                <FormGroup className="flow" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "flow"} onClick={() => this.setState({ formaPago: "flow" })} />{' '}
                                    <img src="https://recaudador.asyntec.com/logoflow.png" width="60" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.khipu &&
                                <FormGroup className="khipu" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "khipu"} onClick={() => this.setState({ formaPago: "khipu" })} />{' '}
                                    <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2015/110x50-transparent.png" width="80" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.mach &&
                                <FormGroup className="mach" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "mach"} onClick={() => this.setState({ formaPago: "mach" })} />{' '}
                                    <img src="https://recaudador.asyntec.com/logomach.png" width="80" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.bci &&
                                <FormGroup className="bci" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "bci"} onClick={() => this.setState({ formaPago: "bci" })} />{' '}
                                    <img src="https://recaudador.asyntec.com/logobci.png" width="80" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.servipag &&
                                <FormGroup className="servipag" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "servipag"} onClick={() => this.setState({ formaPago: "servipag" })} />{' '}
                                    <img src="https://cdn.smartx.cl/images/logo-servipag.png" width="60" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.unired &&
                                <FormGroup className="unired" check inline>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "unired"} onClick={() => this.setState({ formaPago: "unired" })} />{' '}
                                    <img src="https://recaudador.asyntec.com/unired.png" width="60" />
                                  </Label>
                                </FormGroup>}
                              {recaudadores.linkpago &&
                                <FormGroup className="linkpago" check inline style={{ display: "none" }}>
                                  <Label check>
                                    <Input type="radio" checked={this.state.formaPago == "linkpago"} onClick={() => this.setState({ formaPago: "linkpago" })} />
                                  </Label>
                                </FormGroup>}
                            </div>
                            <p>
                              {API.Cookie && <input type="hidden" name="token" value={API.Cookie} />}
                              <input type="hidden" name="monto" value={pago} />
                              <input type="hidden" name="id_servicio" value={this.state.servicio.servicio} />
                              <input type="hidden" name="noDoc" value={!_.isEmpty(this.state.servicio.Documentos) ? this.state.servicio.Documentos[this.state.opcionPago].numero : null} />
                              <Button style={{ marginTop: "2rem", float: "right" }} /* onClick={this.Pagar} */ color="primary" disabled={!this.state.formaPago || !pago || (!this.props.Usuario && !this.state.correoValido)}>Pagar</Button>
                            </p>
                            <div style={{ clear: "both" }}></div>
                          </Form>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>}
      </div>
    );
  }
}

export default withRouter(PagaTuCuenta);